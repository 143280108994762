import { AfterViewInit, Component, EventEmitter, forwardRef, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subscription } from "rxjs";
import { AppConfigService } from 'src/app/appconfig/appconfig.service';
import { CommercialPlan, Plan } from 'src/app/_models/plan';
import { RatesResponse } from 'src/app/_models/rates';
import { ProductTabsetService } from 'src/app/_services/product-tabset.service';
import brandInfo from "src/environments/brand.json";
import { CollapseComponent } from 'ng-uikit-pro-standard';
import { Cart, CartPlan } from 'src/app/_models/cart';
import { Lead } from 'src/app/_models/lead';
import { Opportunity } from 'src/app/_models/account';
import { Vehicle } from 'src/app/_models/vehicle';
import { TaxRequest } from 'src/app/_models/tax';
import { submitTax } from 'src/app/_actions/tax.actions';
import { updateCart } from 'src/app/_actions/cart.actions';
import { Tooltip } from 'chart.js';
import { submitVertexTax } from 'src/app/_actions/vertex-tax.actions';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-ons',
  templateUrl: './add-ons.component.html',
  styleUrls: ['./add-ons.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddOnsComponent),
      multi: true
    }
  ]
})



export class AddOnsComponent implements ControlValueAccessor, OnInit {
  @ViewChildren(CollapseComponent) collapseComponents!: QueryList<CollapseComponent>
  @Output() chosenTerm = new EventEmitter<any>();
  surcharges: any = []
  preferredLanguage: string;

  onChange: any = () => { };
  onTouch: any = () => { };
  dealerSub: Subscription;
  dealer: any;



  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  constructor(
    private store: Store<{
      dealer: any
    }>,
    private appConfig: AppConfigService
  ) { }

  ngOnInit(): void {
    this.preferredLanguage = sessionStorage.getItem("preferredLanguage") ? sessionStorage.getItem("preferredLanguage") : brandInfo.language;
    const _rates: RatesResponse = JSON.parse(sessionStorage.getItem("rates"))

    for (let i = 0; i < _rates.data.productDetailsList.length; i++) {
      for (let j = 0; j < _rates.data.productDetailsList[i].surcharge.surchargeOption.length; j++) {
        this.surcharges.push({
          ..._rates.data.productDetailsList[i].surcharge.surchargeOption[j],
          tooltip: this.getToolTip(_rates.data.productDetailsList[i].surcharge.surchargeOption[j].label.enLabel),
          active: this.getToggleActive(_rates.data.productDetailsList[i].surcharge.surchargeOption[j].label.enLabel)
        });
      }
    }

    this.dealerSub = this.store.select((store) => store.dealer).subscribe((dealer) => {
      this.dealer = dealer;
    })

    console.log('surcharge', this.surcharges)
  }

  getToggleActive(surcharge) {
    const _cart: Cart = JSON.parse(sessionStorage.getItem("cart"));
    if (surcharge.toLowerCase().includes('Grey Market'.toLowerCase())) {
      return _cart.greyMarket;
    } else if (surcharge.toLowerCase().includes('Light Duty'.toLowerCase())) {
      return _cart.commercialUse;
    } else if (surcharge.toLowerCase().includes('Lift Kit'.toLowerCase())) {
      return _cart.liftKit;
    } else if (surcharge.toLowerCase().includes('Plow'.toLowerCase())) {
      return _cart.snowPlow;
    } else if (surcharge.toLowerCase().includes('Professional Services'.toLowerCase())) {
      return _cart.commercialUse;
    }
  }

  getToolTip(surcharge) {
    if (surcharge.toLowerCase().includes('Grey Market'.toLowerCase())) {
      return this.appConfig.getGreyMarketTooltip();
    } else if (surcharge.toLowerCase().includes('Light Duty'.toLowerCase())) {
      return this.appConfig.getCommercialUseTooltip();
    } else if (surcharge.toLowerCase().includes('Lift Kit'.toLowerCase())) {
      return this.appConfig.getLiftKitTooltip();
    } else if (surcharge.toLowerCase().includes('Plow'.toLowerCase())) {
      return this.appConfig.getSnowPlowTooltip();
    } else if (surcharge.toLowerCase().includes('Professional Services'.toLowerCase())) {
      return this.appConfig.getCommercialUseTooltip();
    }
  }

  checked: boolean = false;
  writeValue(checked: boolean) {
    this.checked = checked;
  }


  onModelChange(surcharge, e: boolean) {
    // Step 5a: bind the changes to the local value
    console.log("onModelChange", surcharge, e)
    surcharge.active = e;
    this.handleAddOns(this.surcharges, surcharge, e)
  }

  toggleToolTip(index) {
    this.collapseComponents.toArray()[index].toggle()
  }

  handleAddOns(surcharges, surcharge, boolean) {
    let _cart: Cart = JSON.parse(sessionStorage.getItem("cart"));
    const _plan: Plan = JSON.parse(sessionStorage.getItem("plan"));
    const _rates: any = JSON.parse(sessionStorage.getItem("rates"));
    let surchargePrice = 0

    for (let i = 0; i < surcharges.length; i++) {
      if (surcharges[i].active) {
        surchargePrice += Number(surcharges[i].surchargeAmount)
      }
    }


    if (surcharge.label.enLabel.toLowerCase().includes('Grey Market'.toLowerCase())) {
      _cart.greyMarket = boolean;
    }

    if (surcharge.label.enLabel.toLowerCase().includes('Light Duty'.toLowerCase())) {
      _cart.commercialUse = boolean;
    }

    if (surcharge.label.enLabel.toLowerCase().includes('Professional Services'.toLowerCase())) {
      _cart.commercialUse = boolean;
    }

    if (surcharge.label.enLabel.toLowerCase().includes('Lift Kit'.toLowerCase())) {
      _cart.liftKit = boolean;
    }

    if (surcharge.label.enLabel.toLowerCase().includes('Plow'.toLowerCase())) {
      _cart.snowPlow = boolean;
    }

    if (brandInfo.multiDeductible) {
      const planPrice = _rates.data.productDetailsList[0].productPlanList[_plan.planIndex].planTermSKUList[_plan.termIndex].deductible.filter((deductible) => {
        return deductible.amount === _cart.deductible && deductible.disappearing === _cart.isDisappearing
      })

      console.log('planPrice', planPrice[0].price)
      _cart.subtotal = Number(planPrice[0].price) + surchargePrice
      _cart.downPayment = brandInfo.brand === 'Hyundai' ? _cart.subtotal * .20 : _cart.subtotal * .10
      _cart.plans[0].planPrice = planPrice[0].price
    } else {
      _cart.subtotal = Number(_rates.data.productDetailsList[0].productPlanList[_plan.planIndex].planTermSKUList[_plan.termIndex].planTermSKURate) + surchargePrice;
      _cart.downPayment = brandInfo.brand === 'Hyundai' ? _cart.subtotal * .20 : _cart.subtotal * .10
      _cart.plans[0].planPrice = _rates.data.productDetailsList[0].productPlanList[_plan.planIndex].planTermSKUList[_plan.termIndex].planTermSKURate
    }

    // _cart.downPayment = this.getDownPayment(_cart)

    if (brandInfo.frontLoadTaxes) {
      _cart.monthlyPayment = Number((Number(_cart.subtotal) / Number(_cart.numberOfMonthlyPayments)).toFixed(2).toString());
    } else {
      //Handle downpayment here
      _cart.monthlyPayment = Number(((Number(_cart.subtotal) - Number(_cart.downPayment)) / Number(_cart.numberOfMonthlyPayments)).toFixed(2).toString());
    }

    this.store.dispatch(updateCart(_cart))
    console.log('addOns submitTax');

    this.submitTax(_cart)
  }

  submitTax(cart: Cart) {
    const rates: RatesResponse = JSON.parse(sessionStorage.getItem("rates"));
    const lead: Lead = JSON.parse(sessionStorage.getItem("lead"));
    const opp: Opportunity = JSON.parse(sessionStorage.getItem('opp'));
    const vehicle: Vehicle = JSON.parse(sessionStorage.getItem('vehicle'))

    let taxRequest: TaxRequest = {
      opportunityId: null,
      city: sessionStorage.getItem('appFlowState') !== 'serviceDrive' ? '' : this.dealer.data.address.city,
      country: brandInfo.country ? brandInfo.country : 'USA',
      email: lead && lead.customer.emailId ? lead.customer.emailId : 'noreply@sgintl.com',
      firstName: lead && lead.customer.firstName ? lead.customer.firstName : 'GuestFirst',
      lastName: lead && lead.customer.lastName ? lead.customer.lastName : 'GuestLast',
      phoneNumber: lead && lead.customer.phoneNumber1 ? lead.customer.phoneNumber1 || lead.customer.phoneNumber2 : '',
      postalCode: sessionStorage.getItem('appFlowState') !== 'serviceDrive' ? '' : this.dealer.data.address.postalCode,
      state: sessionStorage.getItem('appFlowState') !== 'serviceDrive' ? vehicle.registeredState : this.dealer.data.address.state,
      street1: sessionStorage.getItem('appFlowState') !== 'serviceDrive' ? '' : this.dealer.data.address.street1,
      vin: vehicle.vin,
      brandName: brandInfo.brand,
      downPayment: cart.downPayment,
      installments: cart.numberOfMonthlyPayments,
      items: []





    }

    if (brandInfo.isMultiProduct) {
      for (let i = 0; i < cart.plans.length; i++) {
        let item = {
          productName: cart.plans[i].ratesProductName,
          productSKU: cart.plans[i].productSkuId,
          quantity: 1,
          unitPrice: cart.plans[i].planPrice,
          productSfId: cart.plans[i].productSkuId,
          productCode: cart.plans[i].productCode,
          contractNumber: cart.plans[i].ratesProductName
        };

        taxRequest.items.push(item)
      }
    } else {
        let item = {
          productName: cart.plans[0].ratesProductName,
          productSKU: cart.plans[0].productSkuId,
          quantity: 1,
          unitPrice: cart.subtotal,
          productSfId: cart.plans[0].productSkuId,
          productCode: cart.plans[0].productCode,
          contractNumber: cart.plans[0].ratesProductName
        };

        taxRequest.items.push(item)
      }




    console.log('Tax Request at Shopping Cart (add-ons):', taxRequest)



    if (brandInfo.taxService === 'Vertex') {
      this.store.dispatch(submitVertexTax(taxRequest));
    } else {

      this.store.dispatch(submitTax(taxRequest));
    }
  }
}
