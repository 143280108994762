<app-coverage-options [_deductible]="deductible"
    [plans]="plans"
    [chosenPlan]="chosenPlan"
    [title]="slice.primary.product.coverage_options_title"
    [subTitle]="slice.primary.product.coverage_options_subhead"
    [select_coverage_term]="slice.primary.product.select_coverage_term"
    [select_claim_deductible]="slice.primary.product.select_claim_deductible"
    [estimated_coverage_through]="slice.primary.product.estimated_coverage_through"
    (chosenPlan)="switchPlan($event)"></app-coverage-options>
<hr>
<app-select-your-plan [title]="slice.primary.product.select_your_plan_title"
    (emitAddToCart)="addToCart($event)"
    [subTitle]="slice.primary.product.select_your_plan_subhead" [_deductible]="deductible" [plans]="plans"
    [chosenPlan]="chosenPlan" (chosenPlan)="switchPlan($event)"></app-select-your-plan>
<div *ngFor="let field of slice.fields">
    <div [ngSwitch]="field?.section_type">
        <app-plan-pdf *ngSwitchCase="'sample_pdf'"></app-plan-pdf>
        <app-covered-components *ngSwitchCase="'term_length'" [plan]="chosenPlan"></app-covered-components>
        <app-coverage-overview *ngSwitchCase="'plans_includes'" [plan]="chosenPlan"></app-coverage-overview>
        <app-plans-overview *ngSwitchCase="'plans_overview'" [plan]="chosenPlan"></app-plans-overview>
        <div *ngIf="thisBrand !== 'Hyundai'">
            <app-mobile-plan-footer *ngSwitchCase="'price'" [plan]="chosenPlan"
                (emitAddToCart)="addToCart($event)"></app-mobile-plan-footer>
        </div>
    </div>
</div>
