<footer *ngIf="isFooterVisible">
  <div class="container text-center text-md-left">
    <div class="row justify-content-md-center">
      <div class="col-md-6 footer-group-logo" *ngIf="brandInfo.brand !== 'Lambo'">
        <div class="p-4 footer-logo">
          <img aria-hidden="true" *ngIf="footer?.logo.url" [src]="footer?.logo.url ? footer.logo.url : ''" [alt]="footer?.logo.alt" />
          <div class="nav-dealer" *ngIf="dealerName">{{ dealerName }}</div>
        </div>

      </div>

      <hr class="clearfix w-100 d-md-none" />

      <div class="col-md-6 footer-group-contact-us">
        <div class="font-weight-bold text-uppercase mt-5 mb-4" tabindex="0" role="heading">
          <strong>Contact Us</strong>
        </div>
        <ul class="list-unstyled">
          <li>
            <div tabindex="0"><mdb-icon fas icon="phone"></mdb-icon>{{ branding.cs_phone_number }}</div>
          </li>
          <li>
            <div tabindex="0">{{ branding.cs_email }}</div>
          </li>
          <li>
            <div aria-label="Hours of operation." tabindex="0" class="hours-of-operation">
              <div class="" tabindex="0" [innerHtml]="footer?.hours ? getHtml(footer.hours, false) : ''"></div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="footer-copyright text-center p-4">
    <div class="row justify-content-center">
      <div class="row footer-links">
        <div >
          <a *ngIf="useLegalFolder" tabindex="0" role="link" target="_blank" [attr.aria-label]="('FOOTER.TERMS_CONDITIONS' | translate) + ' ' + ('FOOTER.OPEN_NEW_TAB' | translate)" [routerLink]="['/legal/terms-and-conditions']" [queryParams]="{lang: thisLang}">{{ 'FOOTER.TERMS_CONDITIONS' | translate }}</a>
          <a *ngIf="!useLegalFolder" tabindex="0" role="link" target="_blank" [attr.aria-label]="('FOOTER.TERMS_CONDITIONS' | translate) + ' ' + ('FOOTER.OPEN_NEW_TAB' | translate)" [routerLink]="['/terms-and-conditions']" [queryParams]="{lang: thisLang}">{{ 'FOOTER.TERMS_CONDITIONS' | translate }}</a>
        </div>
        <div >
          <a *ngIf="useLegalFolder" tabindex="0" role="link" target="_blank" [attr.aria-label]="('FOOTER.PRIVACY' | translate) + ' ' + ('FOOTER.OPEN_NEW_TAB' | translate)" [routerLink]="['/legal/privacy-policy']" [queryParams]="{lang: thisLang}">{{ 'FOOTER.PRIVACY' | translate }}</a>
          <a *ngIf="!useLegalFolder" tabindex="0" role="link" target="_blank" [attr.aria-label]="('FOOTER.PRIVACY' | translate) + ' ' + ('FOOTER.OPEN_NEW_TAB' | translate)" [routerLink]="['/privacy-policy']" [queryParams]="{lang: thisLang}">{{ 'FOOTER.PRIVACY' | translate }}</a>
        </div>
        <div *ngIf="isGMBrand">
          <a tabindex="0" role="link" aria-label="California Privacy Rights (opens in a new tab)" (click)="showInterstitial()">{{ 'FOOTER.PRIVACY_CALIFORNIA' | translate }}</a>
        </div>
        <div>
          <a *ngIf="useLegalFolder" tabindex="0" role="link" target="_blank" [attr.aria-label]="('FOOTER.ACCESSIBILITY' | translate) + ' ' + ('FOOTER.OPEN_NEW_TAB' | translate)" [routerLink]="['/legal/accessibility']" [queryParams]="{lang: thisLang}">{{ 'FOOTER.ACCESSIBILITY' | translate }}</a>
          <a *ngIf="!useLegalFolder" tabindex="0" role="link" target="_blank" [attr.aria-label]="('FOOTER.ACCESSIBILITY' | translate) + ' ' + ('FOOTER.OPEN_NEW_TAB' | translate)" [routerLink]="['/accessibility']" [queryParams]="{lang: thisLang}">{{ 'FOOTER.ACCESSIBILITY' | translate }}</a>
        </div>
        <!-- <div>
          <a id="accessibilityWidget" tabindex="0" role="link" aria-label="Accessibility Controls">Accessibility Controls</a>
        </div> -->
      </div>
    </div>
    <div><span tabindex="0" class="rights-reserved" [innerHtml]="footer?.copyright_statement ? checkYear(getHtml(footer.copyright_statement, false)) : ''"></span></div>
    <div class="footer-version">v 6.17.1</div>
    <div class="siteseal"><span id="siteseal"></span></div>
  </div>
</footer>
