<mdb-navbar id="navbar" SideClass="navbar navbar-toggleable-md scrolling-navbar double-nav fixed-top"
  [containerInside]="false" mdbStickyHeader>
  <div class="row w-100 mb-1">

    <mdb-navbar-brand class="mt-md-2 mt-0" [ngClass]="{'col-1':useNissanMenu, 'col-5':!useNissanMenu}"
      style="white-space: nowrap; display: flex; align-items: center;">
      <a class="navbar-brand" href="javascript:void(0)" (click)="redirectToHome()"><img class="logo"
          [src]="logo?.url ? logo.url : ''" [alt]="logo?.alt" /></a>
      <div class="nav-dealer" *ngIf="dealerName">{{dealerName}}</div>
    </mdb-navbar-brand>

    <navlinks [ngClass]="{'col-11':useNissanMenu, 'col-7':!useNissanMenu}">
      <!-- <ul class="nav navbar-nav nav-flex-icons ie-double-nav w-100">
      </ul> -->
      <span *ngIf="!isCheckoutPage">

        <span class="nav-item mobile float-right">
          <a tabindex="0" role="button" class="nav-link waves-light nav-buttons" mdbWavesEffect (click)="openNav()"
            aria-label="Menu"> <span class="clearfix d-none d-sm-inline-block ml-1 mr-2"> </span> <i
              class="fas fa-bars"></i></a>
        </span>

        <span class="nav-item mt-md-2 mt-0 float-right shopping-cart-icon" [ngClass]="{'active': isCartPage}">
          <a tabindex="0" role="button" alt="" aria-hidden="true" class="nav-link waves-light nav-buttons"
            (click)="cartModalShow()" data-toggle="modal">
            <mdb-icon fas icon="shopping-cart"></mdb-icon>
            <span style="display: none;">Shopping Cart</span>
            <span *ngIf="cartCount > 0" class="badge badge-primary">{{cartCount}}</span>
          </a>

        </span>

        <span class="call nav-item desktop mt-md-2 mt-0 float-right">
          <a role="link" href="tel:{{ branding.cs_phone_number }}" tabindex="0"
            class="phone_number nav-link waves-light d-sm-inline-block nav-buttons" mdbWavesEffect
            [attr.aria-label]="setCallUs(branding.cs_phone_number)">
            <mdb-icon fas icon="phone"></mdb-icon><span>{{ branding.cs_phone_number }}</span>
          </a>
        </span>

        <span class="call nav-item mobile mt-md-2 mt-0 float-right">
          <a role="link" href="tel:{{ branding.cs_phone_number }}" tabindex="0"
            class="phone_number nav-link waves-light d-sm-inline-block nav-buttons" mdbWavesEffect
            [attr.aria-label]="'NAV.CALL_US' | translate">
            <mdb-icon fas icon="phone"></mdb-icon><span> {{ 'NAV.CALL_US' | translate }}</span>
          </a>
        </span>

        <div *ngIf="isLogin" class="nav-item desktop mt-md-2 mt-0 float-right d-sm-inline-block nav-buttons myaccount"
          mdbDropdown>
          <a mdbDropdownToggle class="dropdown-toggle" tabindex="0" role="link">{{ 'NAV.MY_ACCOUNT' | translate }}</a>
          <div class="dropdown-menu">
            <a role="link" class="waves-light d-sm-inline-block nav-buttons" routerLink="/my-account"
              routerLinkActive="active" mdbWavesEffect>{{ 'NAV.MY_ORDER' | translate }}</a>
            <a role="link" class="waves-light d-sm-inline-block nav-buttons" mdbWavesEffect href="javascript:void(0)"
              (click)="signOut()">{{ 'NAV.SIGN_OUT' | translate }}</a>
          </div>
        </div>

        <span *ngIf="!isLogin" class="nav-item desktop mt-md-2 mt-0 float-right" routerLinkActive="active">
          <a role="link" routerLink="/verify-account" href="javascript:void(0)"
            class="nav-link waves-light d-sm-inline-block nav-buttons" mdbWavesEffect>{{ 'NAV.MY_ACCOUNT' | translate
            }}</a>
        </span>

        <span (click)="goToHelp()" class="nav-item desktop mt-md-2 mt-0 float-right" [ngClass]="{'active': isHelpPage}">
          <a role="link" href="javascript:void(0)" tabindex="0"
            class="nav-link waves-light d-sm-inline-block nav-buttons" mdbWavesEffect>{{ 'NAV.HELP' | translate }}</a>
        </span>

        <span *ngIf="useNissanMenu"
          class="nav-item desktop mt-md-2 mt-0 float-right d-sm-inline-block nav-buttons myaccount" mdbDropdown
          [ngClass]="{'active': currentProductLink !== compareProduct}">
          <a mdbDropdownToggle class="dropdown-toggle nav-products" tabindex="0" role="link">{{currentProductLink}}</a>
          <div class="dropdown-menu">
            <a role="link" tabindex="0" routerLink="/added-security-plan" (click)="redirectTo('/added-security-plan')"
              routerLinkActive="active" class="waves-light nav-buttons" mdbWavesEffect style="white-space: nowrap;">{{
              'NAV.PRODUCT1' | translate }}</a>
            <a role="link" tabindex="0" routerLink="/prepaid-maintenance" (click)="redirectTo('/prepaid-maintenance')"
              routerLinkActive="active" class="waves-light nav-buttons" mdbWavesEffect style="white-space: nowrap;">{{
              'NAV.PRODUCT2' | translate }}</a>
          </div>
        </span>

        <span *ngFor="let link of navLinks" class="nav-item desktop mt-md-2 mt-0 float-right"
          [ngClass]="{'active': currentURL == link.url}">
          <a [href]="link.url" class="nav-link waves-light d-sm-inline-block nav-buttons">{{link.text}}</a>
        </span>

        <span class="nav-item desktop mt-md-2 mt-0 float-right" *ngIf="showSDLogout">
          <mdb-icon aria-hidden="true" width="100%" fas icon="sign-out-alt" style="font-size:13px"></mdb-icon>&nbsp;<a role="link" tabindex="0" (click)="logoutDealerAgent()"
            class="nav-link waves-light d-sm-inline-block nav-buttons">{{dealerAgent}}</a>
        </span>

      </span>
      <span class="checkout nav-item mt-md-2 mt-0 float-right" *ngIf="isCheckoutPage">
        <h3>{{ 'CART_CHECKOUT.CHECKOUT' | translate }}</h3>
      </span>

    </navlinks>
  </div>
</mdb-navbar>