import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'apollo-client/util/Observable';
import { CollapseComponent } from 'ng-uikit-pro-standard';
import { storePrismicPDF } from 'src/app/_actions/pdf.actions';
import { savePlan } from 'src/app/_actions/plan.actions';
import { PrismicPDF } from 'src/app/_models/pdf';
import { Plan } from 'src/app/_models/plan';
import { RatesResponse } from 'src/app/_models/rates';
import { ProductTabsetService } from 'src/app/_services/product-tabset.service';
import brandInfo from '../../../../../environments/brand.json'
import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';

@Component({
  selector: 'app-plan-select',
  templateUrl: './plan-select.component.html',
  styleUrls: ['./plan-select.component.scss']
})
export class PlanSelectComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Input() plans: any;
  @Input() _deductible: string;
  @Output() chosenPlan = new EventEmitter<any>();
  @Output() changeTerms = new EventEmitter<boolean>()
  @Output() emitAddToCart = new EventEmitter<string>();
  slices: any[];
  priceHeader: string;
  coveredParts: [];
  coverageBoxes: any[];
  coverageHeader: string;
  coverageText: string;
  protectionText: string;
  faqTitle: string;
  faq: any[];
  paymentOptions: boolean;
  ratesSubscription: Subscription;
  multipleDeductibles = false;
  deductibles: any[];
  currentDeductible: number;
  disappearingDeductibleValue = false;
  disappearingDeductibleDisclaimer = false;
  noFee = 'no-fee';
  preferredLanguage: string;
  planType: boolean;
  showSevere = false;

  @ViewChildren('currentDeductibleMobilePanel') currentDeductibleMobilePanel: QueryList<CollapseComponent>;
  @ViewChildren('currentDeductiblePanel') currentDeductiblePanel: QueryList<CollapseComponent>;
  @ViewChildren('disappearingDeductible') disappearingDeductible: QueryList<CollapseComponent>;
  @ViewChildren('disappearingDeductibleMobile') disappearingDeductibleMobile: QueryList<CollapseComponent>;
  frontLoadTaxes: boolean;
  brand: string;
  canSelect = true;
  severePlans = 0
  normalPlans = 0;
  readyToShow = false;

  constructor(
    private el: ElementRef,
    public store: Store<{
      rates: RatesResponse
    }>,
    private productTabSetService: ProductTabsetService
  ) { }

  ngOnInit(): void {
    this.brand = brandInfo.brand;
    this.preferredLanguage = sessionStorage.getItem("preferredLanguage") ? sessionStorage.getItem("preferredLanguage") : brandInfo.language;
    registerLocaleData(localeFr, 'fr');
    registerLocaleData(localeEn, 'en-ca');
    registerLocaleData(localeEn, 'en-us');

    if (this.preferredLanguage.indexOf('fr') > -1) {
      this.noFee = 'pas de frais';
    }

    this.deductibles = [];

    console.log('Current Plans - Deductibles', this.plans)

    if (brandInfo.multiDeductible) {
      this.setDeductible()
    }

    // this.currentDeductible = this.deductibles[this.deductibles.length - 1];

    // this.setPlan(0);
    this.ratesSubscription = this.store.select(store => store.rates)
      .subscribe(rates => {
        if (rates.data.paymentOptions && brandInfo.isPaymentOptions) {
          this.paymentOptions = true
        } else {
          this.paymentOptions = false
        }

      })

    if ((brandInfo.brand === "Buick") ||
      (brandInfo.brand === "Chevrolet") ||
      (brandInfo.brand === "GMC") ||
      (brandInfo.brand === "Cadillac") ||
      (brandInfo.brand === "BMW") ||
      (brandInfo.brand === "MINI") ||
      (brandInfo.brand === "Schomp")) {
      this.multipleDeductibles = true;
      this.getProperPricing()
    }

    this.frontLoadTaxes = brandInfo.frontLoadTaxes
    if ((brandInfo.brand === "Buick") ||
      (brandInfo.brand === "Chevrolet") ||
      (brandInfo.brand === "GMC") ||
      (brandInfo.brand === "Cadillac") ||
      (brandInfo.brand === "BMW") ||
      (brandInfo.brand === "MINI")) {
      this.noFee = ''
    }

    const program = JSON.parse(sessionStorage.getItem("presetProgram"))
    if (program && program.deductible) {
      this.deductibles.forEach(deductible => {
        if (deductible === Number(program.deductible)) {
          this.chooseDeductible(program.deductible)
        }
      })
    }

    this.plans.forEach((plan, index) => {
      if (plan.plan_type) {
        console.log('Found a severe plan', plan)
        this.severePlans++
      } else if (!plan.plan_type) {
        console.log('Found a normal plan', plan)
        this.normalPlans++
      }
    })

    console.log('Severe Plans', this.severePlans)
    console.log('Normal Plans', this.normalPlans)

    if (brandInfo.brand === 'Hyundai') {
      setTimeout(() => { // use setTimeout to avoid a race condition with rearranging the plans by total payment price
        this.plans.sort((a, b ) => (a.term.totalPrice > b.term.totalPrice) ? 1 : ((b.term.totalPrice > a.term.totalPrice) ? -1 : 0));
        this.onTypeChange(false) // set the first plan as the active plan on normal array
        this.readyToShow = true;
      }, 0)
    }
  }

  onTypeChange(e) {
    this.plans.forEach((plan) => {
      plan.active = false;
    });
    let foundPlan = false
    if (e) {
      this.showSevere = true;
      this.plans.forEach((plan) => {
        if (plan.plan_type && !foundPlan) {
          plan.active = true;
          foundPlan = true;
        }
      });
    } else {
      this.showSevere = false;
      this.plans.forEach((plan) => {
        if (!plan.plan_type && !foundPlan) {
          plan.active = true;
          foundPlan = true;
        }
      });
    }
  }

  ngAfterViewInit() {
    this.resizeTabs();
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.resizeTabs();
  }

  setDeductible() {
    for (let i = 0; i < this.plans.length; i++) {
      if (this.plans[i].active === true) {
        for (let j = 0; j < this.plans[i].planTerms[0].deductible.length; j++) {

          this.deductibles.push(this.plans[i].planTerms[0].deductible[j].amount)
        }
        this.deductibles = this.deductibles.sort((a, b) => {
          return a - b
        })
        this.deductibles = [...new Set(this.deductibles)]
        this.currentDeductible = this.deductibles[0];
        return
      }
    }
  }

  resizeTabs() {
    let planDivs = this.el.nativeElement.querySelectorAll(".plan-select");
    let height = 0;
    planDivs.forEach(element => {
      height = element.firstChild.clientHeight > height ? element.firstChild.clientHeight : height;
    });
    planDivs.forEach(element => {
      if (element.classList.contains('active')) {
        element.style.height = (height + 25) + 'px';
      } else {
        element.style.height = (height + 20) + 'px';
      }
    })
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    //console.log('Are you seeing me: ', simpleChanges.plans.isFirstChange)
    if (simpleChanges.plans && simpleChanges.plans.isFirstChange) {
      const program = JSON.parse(sessionStorage.getItem("presetProgram"))
      let hasPresetValue = null;
      if (program && program.deductible && this.deductibles) {
        this.deductibles.forEach(deductible => {
          if (deductible === Number(program.deductible)) {
            hasPresetValue = program.deductible
          }
        })
      }
      if (hasPresetValue) {
        this.currentDeductible = hasPresetValue
      } else {
        this.currentDeductible = Number(this._deductible);
      }
    } else {
      this.currentDeductible = Number(this._deductible);
    }

    // if (this.currentDeductible === 100) {
    //   this.disappearingDeductibleDisclaimer = true;
    // } else {
    //   this.disappearingDeductibleDisclaimer = false;
    // }

    this.chooseDeductible(this.currentDeductible)
  }


  ngOnDestroy() {
    if (this.ratesSubscription) {
      this.ratesSubscription.unsubscribe()
    }
  }

  toggleDisappearing() {
    this.disappearingDeductibleValue = !this.disappearingDeductibleValue
    this.getProperPricing()
  }


  getProperPricing() {
    if (brandInfo.multiDeductible) {
      let _rates: any = JSON.parse(sessionStorage.getItem("rates"));
      for (let i = 0; i < this.plans.length; i++) {
        if (this.plans[i].active === true) {
          console.log('Current Active Plan', this.plans[i])
          console.log('Chosen deductible', this.currentDeductible)
          this.plans[i].plan.deductible = this.currentDeductible
          const info = this.plans[i].planTerms[this.plans[i].term.termIndex].deductible.filter(item => {
            return item.amount === this.currentDeductible && item.disappearing === this.disappearingDeductibleValue
          })

          if (info.length > 0) {
            this.plans[i].term.disappearingDeductibleSelected = info[0].disappearing
            this.plans[i].term.contractDealerCost = info[0].price
            this.plans[i].term.productPlanSkuId = info[0].sku

            let surcharges = 0
            if (this.plans[i].term.commercialUseSelected) {
              surcharges += this.productTabSetService.getPriceForSurcharge('Light Duty')
            }

            if (this.plans[i].term.commercialUseSelected) {
              surcharges += this.productTabSetService.getPriceForSurcharge('Professional Services')
            }

            if (this.plans[i].term.liftKitSelected) {
              surcharges += this.productTabSetService.getPriceForSurcharge('Lift Kit')
            }

            if (this.plans[i].term.greyMarketSelected) {
              surcharges += this.productTabSetService.getPriceForSurcharge('Grey Market')
            }

            if (this.plans[i].term.snowPlowSelected) {
              surcharges += this.productTabSetService.getPriceForSurcharge('Plow')
            }

            if (!this.plans[i].term.commercialUseSelected && !this.plans[i].term.liftKitSelected && !this.plans[i].term.greyMarketSelected && !this.plans[i].term.snowPlowSelected) {
              this.plans[i].term.totalPrice = info[0].price
            } else {
              this.plans[i].term.totalPrice = Number(info[0].price) + surcharges
            }


            this.plans[i].term.price = brandInfo.brand === 'Hyundai' ? (Number(this.plans[i].term.totalPrice) * .20).toFixed(2).toString() : (Number(this.plans[i].term.totalPrice) * .10).toFixed(2).toString()

            if (brandInfo.frontLoadTaxes) {
              this.plans[i].term.payment = ((Number(this.plans[i].term.totalPrice)) / Number(this.plans[i].term.numberOfPayments)).toFixed(2).toString()
            } else {
              this.plans[i].term.payment = ((Number(this.plans[i].term.totalPrice - Number(this.plans[i].term.price))) / Number(this.plans[i].term.numberOfPayments)).toFixed(2).toString()
            }

            setTimeout(() => {
              this.chosenPlan.emit(this.plans[i]);
              this.canSelect = true;
            })

            return
          }
        }
      }
    } else {
      for (let i = 0; i < this.plans.length; i++) {
        if (this.plans[i].active === true) {
          // this.plans[i].plan.deductible = this.currentDeductible
          const info = this.plans[i].planTerms[this.plans[i].term.termIndex]

          this.plans[i].term.contractDealerCost = info.price
          this.plans[i].term.productPlanSkuId = info.productPlanSkuId

          let surcharges = 0
          if (this.plans[i].term.commercialUseSelected) {
            surcharges += this.productTabSetService.getPriceForSurcharge('Light Duty')
          }

          if (this.plans[i].term.commercialUseSelected) {
            surcharges += this.productTabSetService.getPriceForSurcharge('Professional Services')
          }

          if (this.plans[i].term.liftKitSelected) {
            surcharges += this.productTabSetService.getPriceForSurcharge('Lift Kit')
          }

          if (this.plans[i].term.greyMarketSelected) {
            surcharges += this.productTabSetService.getPriceForSurcharge('Grey Market')
          }

          if (this.plans[i].term.snowPlowSelected) {
            surcharges += this.productTabSetService.getPriceForSurcharge('Plow')
          }

          if (!this.plans[i].term.commercialUseSelected && !this.plans[i].term.liftKitSelected && !this.plans[i].term.greyMarketSelected && !this.plans[i].term.snowPlowSelected) {
            this.plans[i].term.totalPrice = info.price
          } else {
            this.plans[i].term.totalPrice = Number(info.price) + surcharges
          }


          this.plans[i].term.price = brandInfo.brand === 'Hyundai' ? (Number(this.plans[i].term.totalPrice) * .20).toFixed(2).toString() : (Number(this.plans[i].term.totalPrice) * .10).toFixed(2).toString()

          if (brandInfo.frontLoadTaxes) {
            this.plans[i].term.payment = ((Number(this.plans[i].term.totalPrice)) / Number(this.plans[i].term.numberOfPayments)).toFixed(2).toString()
          } else {
            this.plans[i].term.payment = ((Number(this.plans[i].term.totalPrice - Number(this.plans[i].term.price))) / Number(this.plans[i].term.numberOfPayments)).toFixed(2).toString()
          }
          setTimeout(() => {
            this.chosenPlan.emit(this.plans[i]);
            this.canSelect = true;
          })

          return
        }
      }
    }
  }

  chooseDeductible(deductible) {
    this.disappearingDeductibleValue = false
    if (deductible == 100 && this.currentDeductiblePanel) {
      this.currentDeductiblePanel.forEach((panel) => {
        panel.show();
      });
      this.currentDeductibleMobilePanel.forEach((panel) => {
        panel.show();
      });
    } else if (this.currentDeductiblePanel) {
      this.currentDeductiblePanel.forEach((panel) => {
        panel.hide();
      });
      this.currentDeductibleMobilePanel.forEach((panel) => {
        panel.hide();
      });
      this.disappearingDeductibleMobile.forEach((panel) => {
        panel.hide();
      });
      this.disappearingDeductible.forEach((panel) => {
        panel.hide();
      });
      this.disappearingDeductibleDisclaimer = false;
    }
    this.currentDeductible = deductible;

    this.getProperPricing()

  }

  toggleDisclaimer() {
    this.disappearingDeductibleDisclaimer = !this.disappearingDeductibleDisclaimer;
    if (this.disappearingDeductibleDisclaimer) {
      this.disappearingDeductibleMobile.forEach((panel) => {
        panel.show();
      });
      this.disappearingDeductible.forEach((panel) => {
        panel.show();
      });
    } else {
      this.disappearingDeductibleMobile.forEach((panel) => {
        panel.hide();
      });
      this.disappearingDeductible.forEach((panel) => {
        panel.hide();
      });
    }
  }

  setPlan(which) {
    const thisWhich = which
    if (this.canSelect == true) {
      this.canSelect = false;
      this.plans.forEach((plan, index) => {
        if (index === thisWhich) {
          plan['active'] = true;
        } else {
          plan['active'] = false;
        }
        setTimeout(() => {
          this.resizeTabs();
        })
      });

      let prismicPDF: PrismicPDF = {
        pdf: this.plans[which].samplePDF.url
      }
      this.store.dispatch(storePrismicPDF(prismicPDF))

      this.getProperPricing()
    }
  }
  addToCart(i) {
    this.setPlan(i);
    this.emitAddToCart.emit("addToCart");
  }
}

